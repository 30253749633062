<template>
  <main>
    <Navbar></Navbar>
    <NavBox></NavBox>
    <div>
      <div style="padding: 30px;">
        <van-button @click="search" type="info">搜索</van-button>
        <van-button @click="resetHaveSync">重置质押记录</van-button>
      </div>
      <div class="tableBox">
        <div style="text-align: right">总行数： {{dataList.length}}</div>
        <table>
          <thead>
            <tr>
              <th>sn</th>
              <th>DeeperChain</th>
              <th>发送DPR时间</th>
              <th>质押记录</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in showList" :key="item._id + 'r1'">
              <td>{{ item._id }}</td>
              <td>
                {{item.deeperChain}}
              </td>
              <td>
                <span v-if="item.sendTime/1">{{$moment(item.sendTime/1).format('YYYY-MM-DD HH:mm:ss')}}</span>
              </td>
              <td>
                <div v-for="(syncItem, index) in item.haveSync" :key="syncItem._id">
                  {{index+1}}: {{syncItem.credit}} - <span v-if="syncItem.syncTime/1">{{$moment(syncItem.syncTime/1).format('YYYY-MM-DD HH:mm:ss')}}</span>
                  <van-button size="mini" @click="showId(syncItem)">ID</van-button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from "@/components/Navbar/NavbarAdmin";
import NavBox from '../NavBox.vue'
export default {
  components: {
    Navbar,
    NavBox
  },
  data() {
    return {
      dataList: [],
    };
  },
  computed: {
    showList() {
      return this.dataList
    }
  },

  mounted() {
    // this.getdataList();
  },
  methods: {
    search() {
      let search = prompt();
      this.getdataList(search)
    },
    resetHaveSync() {
      let id = prompt('请输入ID', '')
      if (id) {
        this.$fetch({
          url: "/admin/resetHaveSync",
          method: 'POST',
          data: {
            id
          },
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
          },
        }).then(res => {
          console.log(res)
          this.getdataList()
        })
      }
    },
    showId(item) {
      console.log(item)
      console.log(item._id)
    },
    getdataList(search) {
      this.$fetch({
        url: "/admin/sendRecord",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
        params: {
          search
        }
      }).then((res) => {
        console.log(res)
        let dataList = [];
        let dataObj = {};
        res.data.haveSend.forEach(it => {
          dataObj[it._id] = it
        })
        res.data.haveSync.forEach(it => {
          if (dataObj[it.sn]) {
            if (dataObj[it.sn].haveSync) {
              dataObj[it.sn].haveSync.push(it)
            } else {
              dataObj[it.sn].haveSync = [it]
            }
          } else {
            dataObj[it.sn] = {
              _id: it.sn,
              deeperChain: it.deeperChain,
              haveSync: [it]
            }
          }
        })
        this.dataList = Object.values(dataObj)
      })
    },
  },
};
</script>

<style lang="less" scoped>
.searchBox {
  padding: 10px 30px;
  .leftBox {
    display: flex;
  }
  .rightBox {
    display: flex;
    .formItem {
      margin-left: 30px;
      display: flex;
      align-items: center;
      .titleBox {
        margin-right: 10px;
      }
    }
  }
}
.tableBox {
  padding: 30px;
  font-size: 12px;
  text-align: center;
  table {
    width: 100%;
    th,td {
      padding: 10px 2px;
    }
    tr {
      &:nth-child(even) {
        background: #fff
      }
      &:nth-child(odd) {
        background: #f5f5f5
      }
      &:hover {
        background: #eee
      }
      
    }
  }
}

.addressBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
}
</style>